.gallery_container {
    display: block;
    position: relative; 
    padding: 0;
    margin: 0;
}

.gallery_container ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 10px;
}

.gallery_container li  {
  height: 100px;
  flex-grow: 1;  
}

.gallery_container li img:hover  {
  opacity: 0.5; 
  cursor: pointer;
}



.gallery_container li:last-child {
  flex-grow: 10;
}

.gallery_container img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.selectedImg {
  filter: opacity(60%) grayscale(1);
  
}

.selectedImgLi {
  -webkit-box-shadow: inset 0px 0px 0px 5px orange;
  -moz-box-shadow: inset 0px 0px 0px 5px orange;
  box-shadow: inset 0px 0px 0px 5px orange;
}


.GalleryNavIcon{
    font-size: 5vw;
    color: white;
  }

  #leftGalleryBtn:hover > div > .GalleryNavIcon {
    color: rgb(255, 166, 0);
  }

  #rightGalleryBtn:hover > div > .GalleryNavIcon {
    color: rgb(255, 166, 0);
  }

  #rightGalleryBtn > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
  }

  #leftGalleryBtn > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
  }

  @media screen and (orientation:portrait) {

    .GalleryNavIcon{
      font-size: 8vh;
      color: white;
    }

    #rightGalleryBtn > div {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
    }
  
    #leftGalleryBtn > div {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
    }
  }





#leftGalleryBtn {
    left: 0;
    position: fixed;
    cursor: pointer;
    height: 80vh;
    width: 15vw;
    top: 50%;
    transform: translateY(-50%);
}

#rightGalleryBtn {
    right: 0;
    position: fixed;
    cursor: pointer;
    height: 80vh;
    width: 15vw;
    top: 50%;
    transform: translateY(-50%);
}


#GalleryCloseBtn {
  position: fixed;
  left: 50%;
  width: 10vh;
  bottom: 5vh;
  transform: translateX(-50%);
  height: 10vh;
  color: rgb(255, 153, 0);
  cursor: pointer;
  background-color: black;
  border-radius: 50%;
}

#GalleryDelBtn {
  position: fixed;
  left: 5%;
  width: 6vh;
  bottom: 5vh;
  transform: translateX(-50%);
  height: 6vh;
  color: rgb(255, 0, 0);
  cursor: pointer;
  background-color: black;
  border-radius: 50%;
}


@media (hover: hover) and (pointer: fine) {
  #GalleryCloseBtn {
    position: fixed;
    left: 50%;
    width: 6vh;
    bottom: 2vh;
    transform: translateX(-50%);
    height: 6vh;
    color: rgb(255, 255, 255);
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
  }
}
