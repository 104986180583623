
.AdminMainContainer {
    top: 0; 
    text-align: center;
    margin: 0 auto;
    margin-top: 5vh;
}

.AdminEditContainer {
    min-width: 500px;
    margin: 0 auto;
    padding-top: 4vh;
}

@media screen and (orientation:landscape) {
    .AdminEditContainer {
        width: 50vw;
    }
    .AdminMainContainer {
        width: 60vw;
    }
}
@media screen and (orientation:portrait) {

}



.MyToolTip > .tooltip-inner {
    background-color: red;
}
    
.MyToolTip > .tooltip-arrow::before {
    border-bottom-color: red;
}