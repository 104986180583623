.CommentListGroup {

    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    font-size: calc(6px + 1vmin);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px;
    text-align: left;
}

.CommentUsername {
    color: green;
    justify-content: left;
    text-align: left;
    
}


.CommentDeleter {
    cursor: pointer;
    margin-left: 5px;
}

.commentTableDivider {
    width: 100%;
}

.commentTableDivider td:nth-child(2) {
    width: 1%;
    font-size: calc(6px + 1vmin);
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
}