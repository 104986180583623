.Stammtisch-Container {
 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    overflow-y: scroll;
      overflow-x: hidden;
      padding: 10px;
    
  }

  
  .Header {
      width: 100%;
    display: flex; 
    justify-content: space-between;
    font-size: 80%;
    cursor: pointer;
  }

  .Header-Entry-Left a {
    text-align: left;
    text-decoration: none;
    color: var(--bs-card-cap-color);
  }
  
  .Header-Entry-Left {
    text-align: left;
  }

  .Header-Entry-Left a:hover {
    text-align: left;
    color: whitesmoke;
    text-decoration: none;
  }

  .Header-Entry-Right {
      padding-right: 20px;
    text-align: right;
  }

  .tischCreateButton {
    position: fixed;
    right: 1vw;
    bottom: 1vh;
  }


  @media screen and (orientation:landscape) {
    .StammtischEntry {
      width: 40vw;
      min-width: 400px;
      max-width: 1400px;
    }
  }

  #TischErstellenLabel {
    display: inline-block;
  }

  @media screen and (orientation:portrait) {
    #TischErstellenLabel {
      display: none;
    }
    .StammtischEntry {
      width: 90vw;
      min-width: 400px;
      max-width: 1400px;
    }
  }

  .DeineMutter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
    
  }
 #FilterTable {
    display: inline-block;
    align-self: center;
    align-items: center !important;
    text-align: center;
  }

  .TableSelf {
    align-self: center;
  }

  .FUCKR {
    display: inline-block;
    align-items: center;
  }

  .FUCKTHIS {
    background-color: gray;
    align-items: center;
    text-align: center;
    justify-items: center;
  }

  .RestaurantSearch {
    text-indent: -99999px;
  }

  
  .FilterContainer {
    border: 1px solid red;
    align-items: center;
  }

  .FilterContainer > div {
    
    border: 2px solid green;
  }