
@media screen and (orientation:landscape) {
    .SpitzenreiterContainer {
        width: 50vw;
        text-align: center;
        margin: 0 auto;
    }

}

