.greetingContainer {
    font-size: 2vh;
    color: white;
    margin-top: 3vh;
    margin-bottom: 2vh;
}

.AvatarImage {
    height: 14vh;
    border: 4px solid rgb(22, 22, 22);
}