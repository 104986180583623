.outer {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0);
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -ms-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
    z-index: 9999 !important;
    align-items: center;
    justify-content: center;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .outer2 {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.7);
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -ms-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
    z-index: 9998 !important;
    align-items: center;
    justify-content: center;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  .middle {
    display: flex;
    width: 90%;
    height: 90%;
  }
  .middle2 {
    display: flex;
    width: 90%;
    height: 90%;
  }
  .middle > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    
    /*box-shadow: 0px 0px 95px rgba(255, 255, 255, 1);*/
  }
  .middle2 > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.75)) drop-shadow(0 0 20px rgba(255, 255, 255, 0.75));
  }

  .imghidden {
    display: none;
  }