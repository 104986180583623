.App {
  height: 100vh;
  width: 100vw;
  background: url("./bavaria.jpg") rgba(0, 0, 0, 0.4) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-blend-mode: multiply;
  text-align: center;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: rgb(40,40,40) !important;
  border-color: black;
}


.App-header {
 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  overflow-y: scroll;
    overflow-x: hidden;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #83280d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #83280d;
}