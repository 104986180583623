.UserButtonContainerActive {
  display: inline-block;
  border: 2px solid rgb(255, 166, 0);
    position: relative;
    display: inline-block;
    text-align: center;
  margin: 0;
  border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
}
.UserButtonContainerActive > img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.UserButtonContainerActive > div {
    background-color: white;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 10px;
  text-align: center;
  display: block;
  position: absolute;
  font-size: 12pt;
  color: rgb(46, 46, 46);
  margin: 0 auto;
  left: 50%;
  bottom: 0;
  transform: translate(-50%,100%);
  cursor: default;
}


.UserButtonContainer {
    
    display: inline-block;
    border: 2px solid rgb(44, 44, 44);
    position: relative;
    display: inline-block;
    text-align: center;
  margin: 0;
  border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
}

.UserButtonContainer:hover {
    border: 2px solid rgb(255, 166, 0);
}

.UserButtonContainer:hover > div {
    display: block;
}

.UserButtonContainer > img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  .UserButtonContainer > div {
      background-color: white;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 10px;
    text-align: center;
    display: none;
    position: absolute;
    font-size: 12pt;
    color: rgb(46, 46, 46);
    margin: 0 auto;
    left: 50%;
    bottom: 0;
    transform: translate(-50%,100%);
    cursor: default;
  }

